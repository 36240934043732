.header {
  border: solid #424224 1px;
  border-collapse: collapse;
  width: 70px;
  background-color: #ffffa3;
}

.cell {
  border: solid #424224 1px;
  border-collapse: collapse;
  height: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #ffffa3;
}

.blank {
  border: solid #424224 1px;
  border-collapse: collapse;
  height: 30px;
  text-align: center;
}